




































































































































import { RequestStatusEnum } from "@/models/global/RequestStatus.enum";
import { computed, defineComponent, ref, watch } from "vue";
import { required } from "vuelidate/lib/validators";
import { excelFormat } from "@/contstants/fileFormats";
import OrderItemLtl from "@/components/order/OrderItemLtl.vue";
import OrderItemFtl from "@/components/order/OrderItemFtl.vue";
import ImportService from "@/models/import/Import.service";
import OrderService from "@/models/order/Order.service";
import OrderToggleBtn from "@/components/import/OrderToggleBtn.component.vue";
import { ImportOrderListItemInterface } from "@/models/import/ImportOrderListItem.interface";
import plural from "@/utils/plural";
import { uniq } from "lodash/fp";
import useVuelidate from "@vuelidate/core";

export default defineComponent({
  name: "Import",
  components: { OrderItemLtl, OrderToggleBtn, OrderItemFtl },
  filters: {
    percent(value: number) {
      return value.toFixed(2);
    }
  },
  setup() {
    const parsedOrders = ref<ImportOrderListItemInterface[]>([]);
    const selectedOrderIdx = ref<number>(0);
    const importReqMessage = ref<string>("");
    const activeOrders = computed<ImportOrderListItemInterface[]>(() => {
      return parsedOrders.value.filter(order => !order.removed);
    });
    const reqStatus = ref<RequestStatusEnum>(RequestStatusEnum.Pending);
    const loading = computed(
      () => reqStatus.value === RequestStatusEnum.Loading
    );
    const loadingProgress = ref<number>(0);
    const file = ref<File | null>(null);
    const ordersForm = ref<any[] | null>(null);

    watch(file, () => (importReqMessage.value = ""));
    const v$ = useVuelidate(
      {
        file: {
          required
        },
        activeOrders: {}
      },
      { file, activeOrders }
    );

    return {
      file,
      parsedOrders,
      reqStatus,
      loading,
      excelFormat,
      selectedOrderIdx,
      importReqMessage,
      activeOrders,
      loadingProgress,
      v$,
      ordersForm
    };
  },
  computed: {
    summary() {
      const loadingAddresses: string[] = [];
      const unloadingAddresses: string[] = [];

      this.parsedOrders[0].places.forEach(place => {
        loadingAddresses.push(place.loadingAddress.address.value);
        unloadingAddresses.push(place.unloadingAddress.address.value);
      });

      const loadingAddressesCountString = plural(
        uniq(loadingAddresses).length,
        "адрес погрузки",
        "адреса погрузки",
        "адресов погрузки",
        true
      );
      const unloadingAddressesCountString = plural(
        uniq(unloadingAddresses).length,
        "адрес выгрузки",
        "адреса выгрузки",
        "адресов выгрузки",
        true
      );

      return `${loadingAddressesCountString}, ${unloadingAddressesCountString}`;
    }
  },
  methods: {
    async uploadFile() {
      this.v$.file.$touch();

      if (this.v$.file.$invalid) {
        return;
      }

      const _importService = new ImportService();
      this.reqStatus = RequestStatusEnum.Loading;
      this.importReqMessage = "";

      const formData = new FormData();

      formData.append("file", this.file as File);

      const errResHandle = () => {
        this.parsedOrders = [];
        this.reqStatus = RequestStatusEnum.Error;
        this.importReqMessage = "Ошибка при разборе файла";
      };

      try {
        const orderItems = await _importService.importFile(formData);
        if (!orderItems) {
          errResHandle();
          return;
        }
        this.parsedOrders = orderItems;
        this.reqStatus = RequestStatusEnum.Pending;
        this.v$.$touch();
      } catch (e) {
        errResHandle();
      }
    },
    monitorOperationProgress(operationId: string): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        const checkProgress = async () => {
          try {
            const _orderService = new OrderService();
            const loadingProgressResponse = await _orderService.getOrderUploadOperationProgress(
              operationId
            );

            if (loadingProgressResponse.isSuccess) {
              const { progress } = loadingProgressResponse.entity;
              this.loadingProgress = progress;

              if (progress >= 100) {
                this.reqStatus = RequestStatusEnum.Success;
                this.parsedOrders = [];
                this.file = null;
                this.v$.file.$reset();

                resolve();
              } else if (progress === -1) {
                this.reqStatus = RequestStatusEnum.Error;
                reject(new Error("Ошибка загрузки"));
              } else {
                setTimeout(checkProgress, 500);
              }
            } else {
              this.reqStatus = RequestStatusEnum.Error;
              reject(new Error("Ошибка сервера"));
            }
          } catch (e) {
            this.reqStatus = RequestStatusEnum.Error;
            reject(new Error("Ошибка"));
          }
        };
        checkProgress();
      });
    },
    async create() {
      const activeOrdersValidation = this.ordersForm?.some(
        (orderComponent, idx) => {
          return !this.parsedOrders[idx].removed && orderComponent.v$.$invalid;
        }
      );

      if (activeOrdersValidation) {
        this.v$.$touch();
        return;
      }
      this.reqStatus = RequestStatusEnum.Loading;

      try {
        const _orderService = new OrderService();
        const response = await _orderService.createOrders(this.activeOrders);

        if (response.isSuccess) {
          this.loadingProgress = 0;
          await this.monitorOperationProgress(response.entity);

          this.reqStatus = RequestStatusEnum.Success;
          this.parsedOrders = [];
          this.file = null;
          this.v$.file.$reset();
        } else {
          this.reqStatus = RequestStatusEnum.Error;
          return;
        }
      } catch (e) {
        this.reqStatus = RequestStatusEnum.Error;
      } finally {
        this.loadingProgress = 0;
      }
    },
    cancelCreation(): void {
      this.file = null;
      this.v$.file.$reset();
      this.parsedOrders = [];
    },
    removeOrder(order: ImportOrderListItemInterface): void {
      order.remove();
      const activeOrderIdx = this.parsedOrders.findIndex(
        order => !order.removed
      );

      if (activeOrderIdx === -1) {
        this.selectedOrderIdx = 0;
        this.cancelCreation();
        return;
      }

      this.selectedOrderIdx = activeOrderIdx;
    }
  }
});
