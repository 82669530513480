
import InputMask from "@/components/UI/InputMask.vue";
import { ContactItemInterface } from "@/models/order/ContactItem.interface";
import { defineComponent, PropType, toRefs } from "vue";
import CountrySelect from "@/components/order/city/CountrySelect.vue";
import { usePhoneMask } from "@/components/order/contacts/compositions/usePhoneMask";
import { useContactValidation } from "@/components/order/contacts/compositions/useContactValidation";
import { useContactMain } from "@/components/order/contacts/compositions/useContactMain";

export default defineComponent({
  name: "DataEdoContact",
  components: {
    CountrySelect,
    InputMask
  },
  props: {
    contact: {
      type: Object as PropType<ContactItemInterface>,
      required: true
    },
    isRequired: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    showRemove: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    contactsPrompt: {
      type: Array as PropType<ContactItemInterface[]>,
      default: () => [] as ContactItemInterface[]
    }
  },
  emits: ["input", "remove"],
  setup(props) {
    const { contact, isRequired } = toRefs(props);
    const { phoneMask } = usePhoneMask(contact);
    const { v$ } = useContactValidation(contact, isRequired);
    const { setContact, changeCountry } = useContactMain(contact);

    return {
      phoneMask,
      v$,
      setContact,
      changeCountry
    };
  }
});
