
import InputMask from "@/components/UI/InputMask.vue";
import { defineComponent, PropType, toRef } from "vue";
import { LoaderItemContractPartyInterface } from "@/models/person/LoaderItem.interface";
import useVuelidate from "@vuelidate/core";
import { helpers, requiredIf } from "@vuelidate/validators";
import { useTaxTypes } from "@/components/personItem/useTaxTypes";
import { usePersonInnFieldViewConfig } from "@/components/personItem/usePersonInnFieldViewConfig";
import { PersonType } from "@/models/person/Person.interface";

export default defineComponent({
  name: "DataEdoLoaderContractParty",
  components: { InputMask },
  props: {
    label: {
      type: String as PropType<string>,
      required: true
    },
    party: {
      type: Object as PropType<LoaderItemContractPartyInterface>,
      required: true
    },
    hasEdm: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    isSender: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  setup(props) {
    const party = toRef(props, "party");
    const { taxesTypes } = useTaxTypes();
    const { returnMaskForInn } = usePersonInnFieldViewConfig();

    const v$ = useVuelidate(
      {
        inn: {
          required: requiredIf(() => props.hasEdm && !props.isSender),
          min: (value: unknown): boolean => {
            const strValue = value as string;

            if (!helpers.req(value)) return true;

            return party.value.type === PersonType.COMPANY
              ? strValue.length === 10
              : strValue.length === 12;
          }
        },
        type: {
          required: requiredIf(() => props.hasEdm && !props.isSender)
        }
      },
      party
    );

    return {
      v$,
      taxesTypes,
      returnMaskForInn
    };
  }
});
