import { ConfigInterface } from "./Config.interface";

import configDev from "./config-files/dev.json";
import configTest from "./config-files/test.json";
import configProd from "./config-files/prod.json";
import configStage from "./config-files/stage.json";

let env = "development";

if (process.env.VUE_APP_MODE) {
  env = process.env.VUE_APP_MODE.trim().toLowerCase();
}

export const configsMap = new Map<string, ConfigInterface>([
  ["development", configDev as ConfigInterface],
  ["test", configTest as ConfigInterface],
  ["stage", configStage as ConfigInterface],
  ["production", configProd as ConfigInterface]
]);

if (!configsMap.has(env)) {
  throw Error(`Could not find config for VUE_APP_CONFIG key "${env}"`);
}

export const config: ConfigInterface = configsMap.get(env) as ConfigInterface;
