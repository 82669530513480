
import {
  defineComponent,
  onMounted,
  PropType,
  toRefs,
  ref,
  computed,
  watch
} from "vue";
import { OrderItemInterface } from "@/models/order/OrderItem.interface";
import useVuelidate from "@vuelidate/core";
import { getContainerTypes, getLoadTypes } from "@/api/infoteka";
import { ContainerTypesInterface } from "@/models/infoteka/containerTypes.interface";
import { LoadTypesInterface } from "@/models/infoteka/loadTypes.interface";
import DataEdoLoading from "@/components/order/data-edo/DataEdoLoading.vue";
import { LoadingAddressObjectInterface } from "@/models/order/AddressObject.interface";
import { PlaceItemInterface } from "@/models/order/PlaceItem.interface";

export default defineComponent({
  components: { DataEdoLoading },
  props: {
    order: {
      type: Object as PropType<OrderItemInterface>,
      required: true
    },
    hasEdm: {
      type: Boolean,
      required: true
    }
  },
  emits: ["update:has-edm"],
  setup(props, { emit }) {
    const { order, hasEdm } = toRefs(props);

    const openedPanelIdx = ref<number>(hasEdm.value ? 0 : -1);
    const containerTypes = ref<ContainerTypesInterface[]>();
    const loadTypes = ref<LoadTypesInterface[]>();
    const loadingAddresses = computed<LoadingAddressObjectInterface[]>(() => {
      return order.value.places.map(
        (place: PlaceItemInterface) => place.loadingAddress
      );
    });

    onMounted(async () => {
      containerTypes.value = await getContainerTypes();
      loadTypes.value = await getLoadTypes();
    });

    const v$ = useVuelidate(
      {
        options: {}
      },
      order
    );

    watch(hasEdm, (value: boolean) => {
      openedPanelIdx.value = value ? 0 : -1;
    });

    function onExpansionPanelChange(panelIdx: number) {
      emit("update:has-edm", panelIdx === 0);
    }

    return {
      v$,
      containerTypes,
      loadTypes,
      openedPanelIdx,
      loadingAddresses,
      onExpansionPanelChange
    };
  }
});
