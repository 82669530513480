
import { computed, defineComponent, PropType, toRefs } from "vue";
import { LoadingAddressObjectInterface } from "@/models/order/AddressObject.interface";
import { PersonType } from "@/models/person/Person.interface";
import DataEdoLoader from "@/components/order/data-edo/DataEdoLoader.vue";
import DataEdoLoadingOwner from "@/components/order/data-edo/DataEdoLoadingOwner.vue";

export default defineComponent({
  components: { DataEdoLoadingOwner, DataEdoLoader },
  props: {
    loadingData: {
      type: Object as PropType<LoadingAddressObjectInterface>,
      required: true
    },
    loadingCount: {
      type: Number,
      required: true
    },
    hasEdm: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const { loadingData, loadingCount } = toRefs(props);

    const title = computed<string>(() => {
      const senderName =
        loadingData.value.person.type === PersonType.COMPANY
          ? loadingData.value.person.name
          : loadingData.value.person.getFullName();

      if (!loadingData.value.address.value || !senderName) {
        return `Данные для погрузки ${loadingCount.value}`;
      }
      if (!loadingData.value.person.inn) {
        return `Данные для погрузки ${loadingCount.value} — ${loadingData.value.address.value}, «${senderName}»`;
      }

      return `Данные для погрузки ${loadingCount.value} — ${loadingData.value.address.value}, «${senderName}» (${loadingData.value.person.inn})`;
    });

    return {
      title
    };
  }
});
