














































































































































































































import { ListItemInterface } from "@/models/global/ListItem.interface";
import {
  getDangerousCargos,
  getBodyTypes,
  getCargoCategories,
  getLoadingType,
  getLoadTypes,
  getContainerTypes
} from "@/api/infoteka";
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  PropType,
  ref,
  watch
} from "vue";
import { OrderOptionsItemInterface } from "@/models/order/OrderOptions.interface";
import BaseInput from "@/components/UI/BaseInput.vue";
import useVuelidate from "@vuelidate/core";
import {
  maxLength,
  minLength,
  required,
  requiredIf
} from "@vuelidate/validators";
import { LoadTypesInterface } from "@/models/infoteka/loadTypes.interface";
import { DangerousCargoItemInterface } from "@/models/order/DangerousCargoItem.interface";
import { useAutocompleteStore } from "@/store/useAutocompleteStore";
import { ContainerTypesInterface } from "@/models/infoteka/containerTypes.interface";

export default defineComponent({
  name: "OrderOptions",
  components: { BaseInput },
  props: {
    options: {
      type: Object as PropType<OrderOptionsItemInterface>,
      required: true
    },
    hasEdm: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const edmAvailable: boolean | undefined = inject("edmAvailable");
    const v$ = useVuelidate(
      {
        bodyTypes: {
          required,
          minLength: minLength(1)
        },
        cargoType: {
          required: requiredIf(() => props.hasEdm)
        },
        cargoShippingTitle: {
          required: requiredIf(() => props.hasEdm),
          max: maxLength(1000)
        },

        cargoCondition: {
          required: requiredIf(() => props.hasEdm),
          max: maxLength(1000)
        },
        packingMethod: {
          required: requiredIf(() => props.hasEdm),
          max: maxLength(1000)
        },
        containerType: {
          required: requiredIf(() => props.hasEdm)
        }
      },
      props.options
    );
    const loadTypes = ref<LoadTypesInterface[]>([]);
    const dangerousCargoItems = ref<DangerousCargoItemInterface[]>([]);
    const isContainer = computed<boolean>(() => {
      return (
        loadTypes.value.find(x => x.id === props.options.cargoType)?.code ==
        "Kontejner"
      );
    });
    const isDangerousCargo = computed<boolean>(() => {
      return (
        loadTypes.value.find(x => x.id === props.options.cargoType)?.code ==
        "Opasnijgruz"
      );
    });
    const autocompleteStore = useAutocompleteStore();

    const containerTypes = ref<ContainerTypesInterface[]>([]);
    const cargoConditions = computed(() => {
      return autocompleteStore.cargoConditions;
    });
    const packingMethods = computed(() => {
      return autocompleteStore.packingMethods;
    });

    onMounted(async () => {
      containerTypes.value = await getContainerTypes();
      loadTypes.value = await getLoadTypes();
      dangerousCargoItems.value = await getDangerousCargos();
    });

    watch(isContainer, val => {
      if (val) {
        props.options.cargoShippingTitle = "Контейнер";
        return;
      }

      props.options.cargoShippingTitle = "";
    });

    function onDangerousCargoInput(itemId: string) {
      if (!itemId) {
        props.options.dangerousClassCode = "";
        return;
      }

      const dangerousGood = dangerousCargoItems.value.find(
        item => item.id === itemId
      );
      props.options.dangerousClassCode = dangerousGood?.class ?? "";
    }

    function onCargoTypeChange() {
      props.options.cargoShippingTitle = "";
    }

    function dangerousCargosFilter(
      item: DangerousCargoItemInterface,
      query: string
    ) {
      const queryLower = query.toLowerCase();

      return (
        item.class.toLowerCase().includes(queryLower) ||
        item.classCode.toLowerCase().includes(queryLower) ||
        item.title.toLowerCase().includes(queryLower) ||
        item.description.toLowerCase().includes(queryLower)
      );
    }

    return {
      v$,
      loadTypes,
      dangerousCargoItems,
      isContainer,
      isDangerousCargo,
      cargoConditions,
      packingMethods,
      containerTypes,
      edmAvailable,
      onDangerousCargoInput,
      dangerousCargosFilter,
      onCargoTypeChange
    };
  },
  data() {
    return {
      cargoCategories: [] as ListItemInterface[],
      bodyTypes: [] as ListItemInterface[],
      loadingTypes: [] as ListItemInterface[],
      temperatureRegimes: [
        {
          id: "Без режима",
          title: "Без режима"
        },
        {
          id: "2 — 6 °C",
          title: "2 — 6 °C"
        },
        {
          id: "12 — 18 °C",
          title: "12 — 18 °C"
        },
        {
          id: "5 — 25 °C",
          title: "5 — 25 °C"
        },
        {
          id: "0 — -5 °C",
          title: "0 — -5 °C"
        },
        {
          id: "-18 °C",
          title: "-18 °C"
        },
        {
          id: "-25 °C",
          title: "-25 °C"
        }
      ] as ListItemInterface[]
    };
  },
  computed: {
    bodyTypeModel: {
      get(): string {
        return this.options.bodyTypes || [];
      },
      set(value: string) {
        this.options.bodyTypes = value;
      }
    }
  },
  mounted() {
    this.loadList();
  },
  methods: {
    async loadList(): Promise<void> {
      Promise.all([
        getCargoCategories(),
        getBodyTypes(),
        getLoadingType()
      ]).then(dataList => {
        this.cargoCategories = dataList[0];
        this.bodyTypes = dataList[1];
        this.loadingTypes = dataList[2];
      });
    }
  }
});
