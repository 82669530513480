var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-btn',{class:[
    {
      'v-btn--disabled': _vm.removed,
      'order-toggle-btn--removed': _vm.removed,
      'order-toggle-btn--hovered': _vm.showRemove
    },
    'order-toggle-btn'
  ],attrs:{"value":_vm.idx,"outlined":"","x-large":""},on:{"mouseover":function($event){_vm.showRemove = true},"mouseout":function($event){_vm.showRemove = false}}},[(_vm.showInvalid)?_c('v-badge',{attrs:{"dot":"","color":"red","offset-x":-5}},[_vm._v(_vm._s(_vm.idx + 1))]):[_vm._v(_vm._s(_vm.idx + 1))],_c('v-fab-transition',[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRemove),expression:"showRemove"}],staticClass:"order-toggle-btn__btn-trash",attrs:{"color":"white"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit(_vm.removed ? 'recover' : 'remove')},"mousedown":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(_vm.removed ? "mdi-undo-variant" : "mdi-trash-can")+" ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }